import React from "react";
import { useTranslation } from "react-i18next";
import NavigationMenu from "../components/NavigationMenu";
import Footer from "../components/Footer";

const styles = {
  container: {
    marginBottom: '3rem',
    marginTop: '10rem',
    padding: '2rem',
    borderRadius: '8px',
    fontFamily: 'K2D, sans-serif',
  },
  title: {
    textAlign: 'center',
    fontSize: '2.5rem',
    marginBottom: '3.5rem',
    color: '#0A2342',
    fontFamily: 'K2D, sans-serif',
  },
  section: {
    marginBottom: '3rem',
    fontFamily: 'K2D, sans-serif',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    marginBottom: '1.5rem',
    color: '#9B2C2C',
    fontFamily: 'K2D, sans-serif',
  },
  sectionContent: {
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#333',
    fontFamily: 'K2D, sans-serif',
  },
};

const Policy = () => {
  const { t } = useTranslation();

  return (
    <div>
      <NavigationMenu />
      <div style={styles.container} className="container">
        <h1 style={styles.title}>{t("policy.title")}</h1>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>{t("policy.introduction.title")}</h2>
          <p style={styles.sectionContent}>{t("policy.introduction.content")}</p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>{t("policy.dataCollection.title")}</h2>
          <p style={styles.sectionContent}>{t("policy.dataCollection.content")}</p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>{t("policy.dataUsage.title")}</h2>
          <p style={styles.sectionContent}>{t("policy.dataUsage.content")}</p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>{t("policy.dataProtection.title")}</h2>
          <p style={styles.sectionContent}>{t("policy.dataProtection.content")}</p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>{t("policy.cookies.title")}</h2>
          <p style={styles.sectionContent}>{t("policy.cookies.content")}</p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>{t("policy.thirdParties.title")}</h2>
          <p style={styles.sectionContent}>{t("policy.thirdParties.content")}</p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>{t("policy.yourRights.title")}</h2>
          <p style={styles.sectionContent}>{t("policy.yourRights.content")}</p>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>{t("policy.contact.title")}</h2>
          <p style={styles.sectionContent}>{t("policy.contact.content")}</p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Policy;
